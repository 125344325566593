import { render, staticRenderFns } from "./DashboardCard.vue?vue&type=template&id=5e62359f&scoped=true&"
import script from "./DashboardCard.vue?vue&type=script&lang=js&"
export * from "./DashboardCard.vue?vue&type=script&lang=js&"
import style0 from "./DashboardCard.vue?vue&type=style&index=0&id=5e62359f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e62359f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VAvatar,VCard,VIcon})
