<template>
  <div class="ma-4">
    <div>
      <v-row class="ma-2">
        <v-col class="pa-0" align="center">
          <v-img :src="require(`../assets/${getLogoName()}`)" :contain="true" style="max-width: 250px; max-height: 250px;" />
        </v-col>
      </v-row>

      <!-- rows with route cards -->
      <div v-for="(row, index) in routeRows" :key="`row-${index}`" class="d-flex mt-5">
        <dashboard-card v-for="card in row" :key="card.title"
                        :card="card.meta" @click="goToRoute(card.name)"
                        width="100%" height="100px" style="flex: 1; min-width: 250px" class="mr-2" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getLogoFilename } from '@/utils/api/config.js'
import DashboardCard from '@/components/DashboardCard.vue'

export default {
  name: 'Dashboard',

  components: {
    DashboardCard
  },

  computed: {
    ...mapState({
      userInfo: state => state.auth.userInfo
    }),

    routeRows() {
      const routeRows = []

      // dynamically create route rows based on the user's capabilities and the max row size
      if (this.userInfo?.account?.capabilities) {
        // define how many elements per route row are allowed
        const maxRouteRowSize = 3
        let currentIndex = 0

        this.$router.options.routes.filter(r => r.meta?.navBar && this.userInfo.account.capabilities.some(c => r.meta?.capability?.includes(c.name)))
          .forEach(link => {
            // create new row with the element
            if (!routeRows[currentIndex]) {
              routeRows.push([link])
            }

            // push the element into the current row
            else if (routeRows[currentIndex] && routeRows[currentIndex].length < maxRouteRowSize) {
              routeRows[currentIndex].push(link)
            }

            // create new row with the element
            else if (routeRows[currentIndex] && routeRows[currentIndex].length === maxRouteRowSize) {
              currentIndex++
              routeRows.push([link])
            }
          })
      }

      return routeRows
    }
  },

  methods: {
    getLogoName() {
      return getLogoFilename()
    },

    goToRoute(name) {
      this.$router.push({ name })
    }
  }
}
</script>
