<template>
  <v-card class="mx-2 pa-6 scroll-bar" @click="$emit('click')">
    <div class="d-flex align-center">
      <v-avatar size="50" color="main">
        <v-icon size="30" color="white">{{ card.icon }}</v-icon>
      </v-avatar>
      <div class="ml-4">
        <p class="ma-0 main--text" style="font-size: 16px">{{ card.title }}</p>
      </div>
    </div>
  </v-card>
</template>


<script>
export default {
  name: 'DashboardCard',

  props: {
    card: {
      type: Object,
      required: true
    }
  }
}
</script>


<style scoped>
.v-icon.v-icon {
  border-radius: 0 !important;
}

.v-card {
  transition: box-shadow 0.3s ease-in-out;
}

.v-sheet.v-card:not(.v-sheet--outlined):hover {
  box-shadow: 0 3px 20px 2px rgba(0, 0, 0, 0.2), 0 2px 20px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
</style>
